import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import HeaderLink from "../components/headerLink"

const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `#B10606`,
      marginBottom: `1.45rem`,
    }}
  >
    <div
      style={{
        margin: `0`,
        maxWidth: 500,
        padding: `1rem 1rem`,
      }}
    >
      <h2 style={{ margin: 0 }}>
        <HeaderLink toLink="/" headerTitle="Home"></HeaderLink>
        <HeaderLink toLink="/about" headerTitle="About"></HeaderLink>
        {/* <HeaderLink toLink="/blog" headerTitle="Blog"></HeaderLink> */}
      </h2>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
