/**
 *  Header Link component for Header on blog pages
 */

import React from "react"
import { Link } from "gatsby"

function HeaderLink({ toLink, headerTitle }) {
  return (
    <Link 
      to= {toLink || "/"}
      style={{
      color: `white`,
      textDecoration: `none`,
      margin: '0 1.5rem',
      fontSize: '1.5rem'
    }}>
     {headerTitle}
    </Link>
  )
}

export default HeaderLink